import { defineStore } from 'pinia';
import { AuthStore } from '@/helpers/types';
import { getItem, removeItem, setItem } from '@/helpers/persistenceStorage';
import { auth } from '@/api';

export const useAuthStore = defineStore('AuthStore', {
  state: (): AuthStore => {
    return {
      token: null,
    };
  },
  getters: {
    loggedIn: () => !!getItem('accessToken'),
  },
  actions: {
    async login(credentials: { email: string; password: string }) {
      const { data } = await auth.login(credentials);
      setItem('accessToken', data);
    },

    async logout() {
      await auth.logout();
      this.clearUserToken();
    },

    clearUserToken() {
      this.token = null;
      removeItem('accessToken');
    },
  },
});
