import { defineStore } from 'pinia';
import { ElNotification } from 'element-plus';
import { MediaStore } from '@/helpers/types';
import { media } from '@/api';

import { useCommonStore } from './CommonStore';

export const useMediaStore = defineStore('MediaStore', {
  state: (): MediaStore => {
    return {
      media: null,
      resources: [],
      fieldUUID: null,
      isFullyLoaded: false,
    };
  },
  getters: {
    getMedia: (state) => state.media,
    getResources: (state) => state.resources,
  },
  actions: {
    async upload(payload: { title: string; description: string; file: File }) {
      const commonStore = useCommonStore();
      commonStore.setProcessingRequest(true);
      try {
        await media.store(payload);
      } catch (e: any) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw Error(message);
      } finally {
        commonStore.setProcessingRequest(false);
      }
    },

    async index(payload?: {
      search: string | null;
      offset: number | null;
      type_name?: string;
    }) {
      const commonStore = useCommonStore();

      commonStore.setProcessingRequest(true);
      try {
        const { data } = await media.index(payload);

        if (payload?.search && payload?.search.length > 2) {
          this.resources = [];
        }

        if (data.length > 0) {
          this.resources = this.resources?.concat(data);
        }

        if (data.length === 0 || data.length < 20) {
          this.isFullyLoaded = true;
        }
      } catch (e: any) {
        // TODO Set errors
      } finally {
        commonStore.setProcessingRequest(false);
      }
    },

    async remove(payload: number) {
      try {
        await media.remove(payload);

        ElNotification({
          type: 'error',
          message: 'Deleted',
        });

        this.resources = this.resources?.filter((item) => item.id !== payload);
      } catch (e: any) {
        ElNotification({
          type: 'error',
          message: 'Something went wrong!',
        });
      }
    },

    async update(payload: { id: number; title: string; description: string }) {
      try {
        await media.update(payload);

        ElNotification({
          type: 'success',
          message: 'Changed',
        });
      } catch (e: any) {
        ElNotification({
          type: 'error',
          message: 'Something went wrong!',
        });
      }
    },
  },
});
