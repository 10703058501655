import { client } from '@/api/client';
import { Language } from '@/helpers/types';

const index = () => {
  return client.get(`/admin/site`);
};

const show = (id: number) => {
  return client.get(`/admin/site/${id}`);
};

const store = (data: {
  name: string;
  slug: string;
  content_group: { name: string };
  languages: number[];
}) => {
  return client.post(`/admin/site`, data);
};

const update = (data: {
  id: number;
  name: string;
  slug: string;
  domain: string;
  languages: Language[];
  content_group: {
    name: string;
  };
}) => {
  const request = JSON.parse(JSON.stringify(data));
  request.languages = data.languages.map((lang: Language) => lang.id);
  return client.put(`/admin/site/${request.id}`, request);
};

const remove = (id: number) => {
  return client.delete(`/admin/site/${id}`);
};

const templates = () => {
  return client.get(`/admin/content-template`);
};

export default {
  index,
  show,
  store,
  update,
  remove,
  templates,
};
