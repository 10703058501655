import { objectForTemplateRequest } from '@/helpers/types';

function create(arr: any) {
  return arr.map((item: any) => createTemplate(item));
}

function createTemplate(item: any) {
  const result: objectForTemplateRequest = {
    name: item.data.label,
    reference_name: item.data.referenceName,
    description: item.data.description,
    type_name: item.type.replace(/-field/g, ''),
    id: item.id,
  };

  // For edit template, remove id
  if (item.is_global || item.id === undefined) {
    delete result.id;
  }

  item.type = item.type.replace(/-field/g, '');

  if (item.type === 'content-reference') {
    result.type_name = item.type.replace(/-reference/g, '');
  }

  if (item.type === 'group') {
    result.type_name = item.type.replace(/group/g, 'field-group');
    result.field_templates = item.fields.map((field: any) =>
      createTemplate(field)
    );
  } else if (item.type === 'repeater') {
    result.field_group_templates = item.field_group_templates?.map(
      (field: any) => createTemplate(field)
    );
  }

  return result;
}

export default {
  create,
};
