import { v4 } from 'uuid';
import { find } from 'lodash';
import { useSitesStore } from '@/stores/SitesStore';
import { Site } from '@/helpers/types';

function createField(field: any) {
  switch (field.type_name) {
    case 'repeater':
      return {
        id: field.id,
        type_name: field.type_name,
        field_template_id: field.field_template.id,
        field_template: field.field_template,
        description: field.description,
        values: field.field_values
          .map(function (value: any) {
            return (value.state || [])
              .map((val: any) => createFieldGroup(val))
              .reduce((a: any, b: any) => a.concat(b), []);
          })
          .reduce((a: any, b: any) => a.concat(b), []),
      };

    case 'field-group':
      return {
        id: field.id,
        type_name: field.type_name,
        field_template_id: field.field_template.id,
        field_template: field.field_template,
        description: field.description,
        values: field.field_values.map((value: any) =>
          createFieldGroup(value.state)
        ),
      };

    case 'image':
      return {
        id: field.id,
        type_name: field.type_name,
        field_template_id: field.field_template.id,
        field_template: field.field_template,
        values: prepareFieldsValues(field),
      };

    case 'file':
      return {
        id: field.id,
        type_name: field.type_name,
        field_template_id: field.field_template.id,
        field_template: field.field_template,
        values: prepareFieldsValues(field),
      };

    case 'boolean':
      return {
        id: field.id,
        type_name: field.type_name,
        field_template_id: field.field_template.id,
        field_template: field.field_template,
        description: field.description,
        values: prepareFieldsValues(field),
      };

    default:
      return {
        id: field.id,
        type_name: field.type_name,
        field_template_id: field.field_template.id,
        field_template: field.field_template,
        values: prepareFieldsValues(field),
      };
  }
}

function createFieldGroup(fieldGroup: any) {
  return {
    field_group_template: {
      id: fieldGroup.template.id,
      name: fieldGroup.template.name,
      description: fieldGroup.template.description,
    },
    parent_id: fieldGroup.parent_id || null,
    field_group_template_id: fieldGroup.template.id,
    fields: fieldGroup?.fields?.map((fieldTemplate: any) =>
      createField(fieldTemplate)
    ),
    order: fieldGroup.order,
    id: fieldGroup.id,
  };
}

function create(fieldGroup: any[]): any[] {
  return fieldGroup.map((fieldGroup: any) => createFieldGroup(fieldGroup));
}

/**
 * So, currently if for existing site add new language, this language will not appear for already existing fields!
 * This ad-hoc function will create all additional values for this purpose.
 */
function prepareFieldsValues(field: any): any[] {
  const siteStore = useSitesStore();
  const site = siteStore.getCurrentSite as Site;
  return site.languages?.map(function (language: any) {
    const fieldValue: any = find(field.field_values, function (obj) {
      return obj.language.id === language.id;
    });
    return {
      _uuid: v4(),
      id: fieldValue?.id,
      state: fieldValue?.state,
      language_id: language.id,
      language_name: language.name,
      language_code: language.code,
    };
  });
}

export default {
  create,
  createFieldGroup,
};
