import { client } from '@/api/client';

export interface ContentOrder {
  content_id: number | null;
  parent_id: number | null;
  order: number;
}

const index = (data: { query?: string; site_id?: number }) => {
  return client.get('/admin/content', {
    params: data,
  });
};

const show = (id: number) => {
  return client.get(`/admin/content/${id}`);
};

const store = (data: object) => {
  return client.post('/admin/content', data);
};

const fieldUpdate = (data: any) => {
  return client.put(`/admin/fields/${data.id}`, data);
};

const update = (data: any) => {
  return client.put(`/admin/content/${data.id}`, data);
};

const remove = (id: number) => {
  return client.delete(`/admin/content/${id}`);
};

const setOrder = (data: ContentOrder[]) => {
  return client.put('/admin/content/order', data);
};

const fieldGroup = (siteID: number, groupID: number, repeaterID: number) => {
  return client.post(`/admin/content/field-group`, {
    site_id: siteID,
    field_group_template_id: groupID,
    field_id: repeaterID,
  });
};

const fieldGroupRemove = (id: number) => {
  return client.delete(`/admin/content/field-group/${id}`);
};

export default {
  index,
  show,
  store,
  update,
  fieldUpdate,
  remove,
  setOrder,
  fieldGroup,
  fieldGroupRemove,
};
