import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { getItem } from '@/helpers/persistenceStorage';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/LoginUser.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('../views/Templates/TemplatesView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/enquiries',
    name: 'enquiries',
    component: () => import('../views/Enquiries/EnquiriesView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sites',
    name: 'sites',
    component: () => import('../views/Sites/SitesView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sites/create',
    name: 'sites/create',
    component: () => import('../views/Sites/CreateSiteView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/site/:id?',
    name: 'site',
    component: () => import('../views/Sites/Edit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/template/create',
    name: 'template/create',
    component: () => import('../views/Builder/FieldsView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/template/:id/edit',
    name: 'template/edit',
    component: () => import('../views/Builder/FieldsView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/template/builder',
    name: 'template/builder',
    component: () => import('../views/Builder/BuilderView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/site/:site_id/content/:parent_id?',
    name: 'content/create',
    component: () => import('@/views/Content/Edit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/site/:site_id/content/:content_id/edit',
    name: 'content/edit',
    component: () => import('@/views/Content/Edit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/languages',
    name: 'languages/index',
    component: () => import('@/views/Languages/index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/languages/create',
    name: 'languages/create',
    component: () => import('@/views/Languages/Create.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/languages/:id/edit',
    name: 'languages/edit',
    component: () => import('@/views/Languages/Edit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = getItem('accessToken');

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
    return;
  }
  next();
  return;
});

export default router;
