import { client } from '@/api/client';

const login = (credentials: { email: string; password: string }) => {
  return client.post(`/auth/login`, credentials);
};

const logout = () => {
  return client.post(`/auth/logout`, null);
};

export default {
  login,
  logout,
};
