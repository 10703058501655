import { defineStore } from 'pinia';
import { user } from '@/api';
import { UserStore } from '@/helpers/types';

export const useUserStore = defineStore('UserStore', {
  state: (): UserStore => {
    return {
      user: null,
      isLoading: true,
    };
  },
  getters: {
    userIsLoading: (state) => state.isLoading,
    currentUser: (state) => state.user,
    userType: (state) => state.user?.user_type ?? 0,
    isAdmin: (state) => state.user?.user_type === 1,
  },
  actions: {
    async getCurrentUser() {
      return await user.getCurrentUser().then((response) => {
        this.isLoading = false;
        this.user = response.data;
        return response.data;
      });
    },

    clear() {
      this.user = null;
    },
  },
});
