export const getItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log('Error getting data from localStorage!', e);
    return null;
  }
};

export const setItem = (key: string, data: string) => {
  try {
    localStorage.setItem(key, data);
  } catch (e) {
    console.log('Error setting data in localStorage!', e);
  }
};

export const removeItem = (key: string) => {
  try {
    return localStorage.removeItem(key);
  } catch (e) {
    console.log('Error removing data from localStorage!', e);
    return null;
  }
};
