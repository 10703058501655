import { client } from '../client';

const index = (search?: { query: string }) => {
  return client.get(`/admin/content-template`, {
    params: search,
  });
};

const show = (id: string) => {
  return client.get(`/admin/content-template/${id}`);
};

const store = (data: any) => {
  return client.post(`/admin/content-template`, data);
};

const update = (id: string, data: any) => {
  return client.put(`/admin/content-template/${id}`, data);
};

const remove = (id: number) => {
  return client.delete(`/admin/content-template/${id}`);
};

const removeField = (id: string, type: string) => {
  return client.delete(`/admin/content-template/${id}/${type}`);
};

const globalTemplates = () => {
  return client.get(`/admin/content-template?name=&is_global=1`);
};

export default {
  index,
  show,
  store,
  update,
  remove,
  removeField,
  globalTemplates,
};
