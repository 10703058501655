import { ElNotification } from 'element-plus';

export default function (title: string, message: string, type: any) {
  ElNotification({
    title,
    message,
    dangerouslyUseHTMLString: true,
    type: type,
  });
}
