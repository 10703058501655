import axios from 'axios';
import { getItem } from '@/helpers/persistenceStorage';

const client = axios.create({
  baseURL: process.env.VUE_APP_API_DOMAIN,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use((config) => {
  const token = getItem('accessToken');
  config.headers!.Authorization = `Bearer ${token}`;
  return config;
});

const sendMultipartRequest = (url: string, data: FormData) => {
  return client.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export { client, sendMultipartRequest };
