import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import './assets/styles/main.scss';
import CKEditor from '@ckeditor/ckeditor5-vue';

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(`Icon${key}`, component);
}

app.use(ElementPlus).use(createPinia()).use(router).use(CKEditor).mount('#app');
