import { content } from '@/api';
import { Content } from '@/helpers/types';
import FieldGroupFactory from '@/factories/FieldGroupFactory';

function create(content: Content): Content {
  const fieldGroups = FieldGroupFactory.create(content.field_groups);

  return {
    id: content.id ?? null,
    // Todo: remove 'parent_id' and replace all using code with the pluralised version
    parent_id: content.parent_id ?? null,
    parents_ids:
      content.parents_ids ??
      content.parents_shallow?.map(({ id }) => id).filter((id) => id) ??
      [],
    content_template_id: content.content_group_id,
    content_group_id: content.content_group_id,
    title: content.title,
    slug: content.slug,
    field_groups: fieldGroups,
    content_template: {
      id: content.content_template.id,
      description: content.content_template.description,
      name: content.content_template.name,
      field_group_templates: content.content_template.field_group_templates,
    },
    title_translations: content.title_translations ?? [],
    parents_shallow: content.parents_shallow ?? [],
    content_group: content.content_group ?? [],
  };
}

function createDuplicateFieldGroup(
  siteID: number | null,
  groupID: number | null,
  repeaterID: number | null,
  data: any,
  field: any
) {
  if (siteID !== null && repeaterID !== null && groupID !== null) {
    content.fieldGroup(siteID, groupID, repeaterID).then((response) => {
      const field = FieldGroupFactory.createFieldGroup(response.data);
      data.push({ openCollapse: true, ...field });
    });
  } else {
    data.push({ openCollapse: true, ...field });
  }
}

function removeFieldGroup(list: any, id: number) {
  const data = list.filter((item: any) =>
    'id' in item ? item.id !== id : item._uuid !== id
  );

  return data;
}

export default {
  create,
  createDuplicateFieldGroup,
  removeFieldGroup,
};
