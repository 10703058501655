import { Field } from '@/helpers/types';
import { v4 } from 'uuid';

function createFieldGroup(
  item: any,
  parentUUID: string | null,
  isGlobalTemplate: boolean
) {
  const type =
    'field_templates' in item
      ? 'group-field'
      : 'field_group_templates' in item && item.field_group_templates.length > 0
      ? 'repeater-field'
      : item.type_name === 'content'
      ? item.type_name + '-reference-field'
      : item.type_name + '-field';

  const uuid = v4();

  const result: Field = {
    type: type,
    uuid: uuid,
    data: {
      label: item.name,
      referenceName: item.reference_name,
      description: item.description,
    },
    id: item.id,
  };

  // For edit template, remove id
  if (isGlobalTemplate) {
    delete result.id;
  }

  if ('field_templates' in item) {
    result.fields = item.field_templates.map((field: Field) =>
      createFieldGroup(field, uuid, isGlobalTemplate)
    );
  } else if ('field_group_templates' in item) {
    if (item.field_group_templates.length > 0) {
      result.field_group_templates = item.field_group_templates.map(
        (field: Field) => createFieldGroup(field, uuid, isGlobalTemplate)
      );
    }
  }

  if (parentUUID !== null) {
    result.parentUUID = parentUUID;
  }

  return result;
}

function create(fieldGroup: [], isGlobalTemplate: boolean) {
  return fieldGroup.map((item: Field) =>
    createFieldGroup(item, null, isGlobalTemplate)
  );
}

export default {
  create,
};
