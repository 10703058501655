import { client, sendMultipartRequest } from '../client';

const URL = `/admin/media`;

const store = (data: { title: string; description: string; file: File }) => {
  const { title, description, file } = data;
  const formData = new FormData();

  if (title) formData.append('title', title);
  if (description) formData.append('description', description);
  if (file) formData.append('file', file);

  return sendMultipartRequest(URL, formData);
};

const index = (data?: {
  search: string | null;
  offset: number | null;
  type_name?: string;
}) => {
  return client.get(URL, {
    params: data,
  });
};

const remove = (id: number) => {
  return client.delete(`/admin/media/${id}`);
};

const update = (data: { id: number; title: string; description: string }) => {
  return client.put(`/admin/media/${data.id}`, {
    title: data.title,
    description: data.description,
  });
};

export default {
  store,
  index,
  update,
  remove,
};
