<template>
  <footer class="footer">
    <!--<a
      class="footer__dev-info"
      href="https://solardigital.com.ua"
      target="_blank"
      rel="noopener nofollow"
    >
      <span class="footer__dev-info-label">Developed by:</span>
      <svg
        width="103"
        height="14"
        viewBox="0 0 103 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5521 5.7826H24.2384C24.1829 5.19 23.7851 4.89806 23.0404 4.89806C22.7212 4.89806 22.4714 4.95907 22.291 5.07671C22.1106 5.19872 22.0181 5.3643 22.0181 5.57345C22.0181 5.64752 22.032 5.70852 22.0551 5.76517C22.0782 5.82181 22.1245 5.8741 22.1892 5.91767C22.254 5.9656 22.3188 6.00482 22.3835 6.03532C22.4483 6.06582 22.5454 6.10068 22.6703 6.13554C22.7952 6.1704 22.9108 6.20526 23.0172 6.2314C23.119 6.25754 23.267 6.29676 23.4567 6.34033C23.6463 6.3839 23.8129 6.42748 23.9516 6.46669C24.5715 6.63227 25.034 6.84142 25.3393 7.09415C25.6446 7.34687 25.7973 7.70417 25.7973 8.1704C25.7973 8.51027 25.7233 8.80657 25.5752 9.05929C25.4272 9.31201 25.2283 9.51245 24.9785 9.6606C24.7287 9.80875 24.4512 9.91768 24.1552 9.9874C23.8545 10.0571 23.5399 10.092 23.2023 10.092C22.3465 10.092 21.6758 9.90896 21.1947 9.53424C20.7137 9.16387 20.4685 8.64099 20.4639 7.96561H21.8331C21.8562 8.71071 22.3326 9.08543 23.2624 9.08543C23.6232 9.08543 23.91 9.01572 24.1181 8.87193C24.3263 8.72813 24.4327 8.53641 24.4327 8.2924C24.4327 8.08761 24.3356 7.9351 24.1459 7.83924C23.9516 7.74338 23.5492 7.61266 22.934 7.44709C22.8322 7.4253 22.7582 7.40351 22.7027 7.39044C22.4899 7.3338 22.3419 7.29458 22.2586 7.26844C22.1754 7.24229 22.0458 7.19872 21.8654 7.13772C21.685 7.07672 21.5555 7.02007 21.4723 6.97214C21.389 6.92421 21.2872 6.85449 21.167 6.76299C21.0467 6.67584 20.9588 6.57998 20.9033 6.48412C20.8478 6.38826 20.8015 6.26626 20.7553 6.12247C20.709 5.97868 20.6905 5.82617 20.6905 5.66059C20.6905 5.12029 20.9126 4.68891 21.3566 4.36647C21.8007 4.04403 22.3881 3.88281 23.1236 3.88281C23.8314 3.88281 24.4142 4.03968 24.8629 4.3534C25.3116 4.67148 25.5429 5.14643 25.5521 5.7826Z"
          fill="white"
        />
        <path
          d="M30.8533 3.86523C31.7784 3.86523 32.5231 4.15282 33.0921 4.73234C33.6611 5.3075 33.9478 6.06131 33.9478 6.99377C33.9478 7.92624 33.6611 8.67569 33.0921 9.2465C32.5231 9.8173 31.7692 10.1049 30.844 10.1049C29.9096 10.1049 29.1556 9.8173 28.5821 9.2465C28.0085 8.67569 27.7217 7.92624 27.7217 6.99377C27.7217 6.0526 28.0085 5.29443 28.5774 4.72362C29.151 4.15282 29.9096 3.86523 30.8533 3.86523ZM30.8394 4.93713C30.3167 4.93713 29.905 5.12014 29.5905 5.49051C29.2805 5.85652 29.1233 6.35325 29.1233 6.97199C29.1233 7.61251 29.2759 8.1136 29.5858 8.47526C29.8911 8.83691 30.3167 9.01992 30.8533 9.02863C31.3806 9.02863 31.7923 8.84563 32.0976 8.47526C32.3982 8.10924 32.5509 7.6038 32.5509 6.97199C32.5509 6.34889 32.3982 5.85216 32.0883 5.49051C31.783 5.12014 31.3621 4.93713 30.8394 4.93713Z"
          fill="white"
        />
        <path
          d="M40.6547 8.84104V9.96086H36.1816V4.00879H37.5647V8.84104H40.6547Z"
          fill="white"
        />
        <path
          d="M46.113 4L48.5184 9.96079H47.0613L46.5663 8.63181H44.2165L43.703 9.96079H42.2783L44.6837 4H46.113ZM46.2287 7.67756L45.396 5.46405L44.5542 7.67756H46.2287Z"
          fill="white"
        />
        <path
          d="M55.1285 6.96739C55.2627 6.86282 55.3829 6.74517 55.4708 6.60574C55.4986 6.55781 55.5263 6.50988 55.5541 6.45759C55.5587 6.44888 55.5633 6.44016 55.5679 6.42709C55.5772 6.41402 55.5818 6.39659 55.5911 6.38352C55.605 6.35737 55.6142 6.32687 55.6281 6.30073C55.6281 6.29637 55.6327 6.29201 55.6327 6.28766C55.6466 6.2528 55.6605 6.21794 55.6697 6.18308C55.6697 6.17872 55.6743 6.17001 55.6743 6.16565C55.679 6.14387 55.6882 6.12208 55.6928 6.09593C55.7252 5.96957 55.7391 5.83885 55.7391 5.69942C55.7391 5.13297 55.5402 4.70596 55.1424 4.42709C54.7446 4.14822 54.2357 4.00879 53.6067 4.00879H50.4658V9.96086H51.8396V7.6297H52.4919C52.5196 7.6297 53.0886 7.6297 53.0886 7.6297V7.63406L54.2728 9.96522H55.7298L54.3375 7.39441C54.6752 7.27676 54.9342 7.12861 55.1285 6.96739ZM51.8396 5.03711H53.2135C53.5697 5.03711 53.8518 5.09376 54.06 5.20269C54.2681 5.31598 54.3745 5.52513 54.3745 5.83885C54.3745 6.17437 54.2681 6.39659 54.0507 6.50988C53.8333 6.62317 53.5326 6.67981 53.1487 6.67981H51.835V5.03711H51.8396Z"
          fill="white"
        />
        <path
          d="M60.9619 4.00391H63.5199C65.4812 4.00391 66.5821 5.05837 66.5821 6.97994C66.5821 8.8928 65.4812 9.95598 63.5199 9.95598H60.9619V4.00391ZM63.3488 8.87101C64.6162 8.87101 65.1851 8.1782 65.1851 6.97994C65.1851 5.78604 64.5977 5.08887 63.3488 5.08887H62.3357V8.86665H63.3488V8.87101Z"
          fill="white"
        />
        <path
          d="M70.2878 9.95598H68.8955V4.00391H70.2878V9.95598Z"
          fill="white"
        />
        <path
          d="M78.5028 9.95637H77.6239L77.4897 9.28098C76.9994 9.84308 76.4073 10.1002 75.5886 10.1002C73.8308 10.1002 72.6143 8.83654 72.6143 7.00647C72.6143 5.16769 73.8909 3.87793 75.7227 3.87793C77.1613 3.87793 78.253 4.70582 78.4195 5.96072H77.078C76.9439 5.33327 76.4397 4.97597 75.7042 4.97597C74.6496 4.97597 74.0066 5.7385 74.0066 7.00647C74.0066 8.30059 74.6449 9.0544 75.7458 9.0544C76.6155 9.0544 77.2214 8.50974 77.2538 7.708H75.843V6.73632H78.4981V9.95637H78.5028Z"
          fill="white"
        />
        <path
          d="M82.3699 9.95598H80.9775V4.00391H82.3699V9.95598Z"
          fill="white"
        />
        <path
          d="M89.6507 5.11502H87.7495V9.95598H86.3572V5.11502H84.4561V4.00391H89.6507V5.11502Z"
          fill="white"
        />
        <path
          d="M96.6309 9.95591H95.1738L94.6789 8.62693H92.329L91.8156 9.95591H90.3955L92.8009 3.99512H94.2302L96.6309 9.95591ZM93.5086 5.45917L92.6667 7.67268H94.3458L93.5086 5.45917Z"
          fill="white"
        />
        <path
          d="M103 9.95598H98.5273V4.00391H99.9104V8.83615H103V9.95598Z"
          fill="white"
        />
        <path
          d="M7.4333 12.8457C9.98799 12.8457 12.059 10.3115 12.059 7.18552C12.059 4.05952 9.98799 1.52539 7.4333 1.52539C4.87861 1.52539 2.80762 4.05952 2.80762 7.18552C2.80762 10.3115 4.87861 12.8457 7.4333 12.8457Z"
          fill="white"
        />
        <path
          d="M7.42884 0C3.32586 0 0 3.1329 0 6.99782C0 10.8627 3.32586 13.9956 7.42884 13.9956C11.5318 13.9956 14.8577 10.8627 14.8577 6.99782C14.8577 3.1329 11.5364 0 7.42884 0ZM9.01082 8.40523C8.99695 8.42702 8.97844 8.44444 8.95994 8.46187L6.38806 10.8802C6.38344 10.8845 6.37881 10.8889 6.36956 10.8932C6.3048 10.9499 6.21691 10.9847 6.1244 10.9847C5.9255 10.9847 5.75897 10.8322 5.75897 10.6405C5.75897 10.549 5.79598 10.4662 5.85611 10.4052C5.86074 10.4009 5.86536 10.3965 5.86999 10.3922L8.43724 7.97386C8.44649 7.96514 8.45574 7.95643 8.46499 7.95207C8.52975 7.90414 8.60839 7.87364 8.69628 7.87364C8.89981 7.87364 9.06171 8.02614 9.06171 8.21786C9.07096 8.28758 9.04783 8.35294 9.01082 8.40523ZM6.52221 7.36383L7.7989 6.16122C7.94229 6.02614 8.17358 6.02614 8.31697 6.16122C8.45574 6.2963 8.45574 6.51416 8.30309 6.64924L7.03103 7.85185C6.95702 7.92157 6.86913 7.95207 6.77662 7.95207C6.68411 7.95207 6.59622 7.91721 6.52221 7.85185C6.37881 7.71242 6.37881 7.49891 6.52221 7.36383ZM9.01082 3.55991C8.99695 3.5817 8.97844 3.59913 8.95994 3.61656L6.38806 6.03486C6.38344 6.03922 6.37881 6.04357 6.36956 6.04793C6.3048 6.10458 6.21691 6.13943 6.1244 6.13943C5.9255 6.13943 5.75897 5.98693 5.75897 5.79521C5.75897 5.7037 5.79598 5.62091 5.85611 5.55991C5.86074 5.55556 5.86536 5.5512 5.86999 5.54684L8.43724 3.12854C8.44649 3.11983 8.45574 3.11111 8.46499 3.10675C8.52975 3.05882 8.60839 3.02832 8.69628 3.02832C8.89981 3.02832 9.06171 3.18083 9.06171 3.37255C9.07096 3.43791 9.04783 3.50327 9.01082 3.55991Z"
          fill="#F50D03"
        />
      </svg>
    </a>-->
  </footer>
</template>

<style lang="scss">
  .footer {
    display: flex;
    align-items: center;
    color: var(--el-color-white);
    background-color: var(--el-color-info-dark-2);
    padding: 10px 20px;
    margin-top: auto;

    &__dev-info {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: var(--el-color-white);
      margin-left: auto;

      &-label {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 5px;
      }
    }
  }
</style>
