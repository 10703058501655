import { defineStore } from 'pinia';
import { CommonStore } from '@/helpers/types';

export const useCommonStore = defineStore('CommonStore', {
  state: (): CommonStore => {
    return {
      isOpenMediaLibraryDrawer: false,
      processingRequest: false,
    };
  },
  getters: {
    isOpenedMediaLibraryDrawer: (state) => state.isOpenMediaLibraryDrawer,
    isRequestInProgress: (state) => state.processingRequest,
  },
  actions: {
    setMediaLibraryDrawer(value: boolean) {
      this.isOpenMediaLibraryDrawer = value;
    },
    setProcessingRequest(payload: boolean) {
      this.processingRequest = payload;
    },
  },
});
