import { ElNotification } from 'element-plus';
import { defineStore } from 'pinia';
import { BuilderStore, Field } from '@/helpers/types';
import { templates } from '@/api';
import TemplateFactory from '@/factories/TemplateFactory';

export const useBuilderStore = defineStore('BuilderStore', {
  state: (): BuilderStore => {
    return {
      template: {
        name: null,
        reference_name: null,
        description: null,
        is_global: false,
      },
      globalTemplates: null,
      data: [],
    };
  },
  getters: {
    getFieldsData: (state) => state.data,
    getTemplateData: (state) => state.template,
    getGlobalTemplates: (state) => state.globalTemplates,
  },
  actions: {
    setFieldsData(payload: any) {
      this.template.name = payload.name;
      this.template.reference_name = payload.reference_name;
      this.template.description = payload.description;
      this.template.is_global = payload.is_global;
      this.data = payload.field_group_templates;
    },
    async storeTemplateData(payload: any) {
      return await templates
        .store(payload)
        .then((response) => {
          ElNotification({
            type: 'success',
            message: 'Template saved!',
          });

          return response.data;
        })
        .catch(() => {
          ElNotification({
            type: 'error',
            message: 'Something went wrong!',
          });
        });
    },
    async showTemplateData(id: string) {
      return await templates
        .show(id)
        .then((response) => {
          const data = response.data;
          this.template.name = data.name;
          this.template.reference_name = data.reference_name;
          this.template.description = data.description;
          this.template.is_global = data.is_global;
          this.data = TemplateFactory.create(data.field_group_templates, false);
        })
        .catch(() => {
          ElNotification({
            type: 'error',
            message: 'Something went wrong!',
          });
        });
    },
    async updateTemplateData(id: string, data: any) {
      return await templates
        .update(id, data)
        .then((response) => {
          ElNotification({
            type: 'success',
            message: 'Template updated!',
          });

          return response.data;
        })
        .catch(() => {
          ElNotification({
            type: 'error',
            message: 'Something went wrong!',
          });
        });
    },
    async removeFieldTemplate(field: Field) {
      let type = 'field';

      if (field.type === 'group-field') {
        type = 'group-field';
      }

      return await templates
        .removeField(String(field.id), type)
        .then((response) => {
          ElNotification({
            type: 'success',
            message: 'Deleted Field!',
          });

          return response.data;
        })
        .catch(() => {
          ElNotification({
            type: 'error',
            message: 'Something went wrong!',
          });
        });
    },
    async setGlobalTemplates() {
      return await templates.globalTemplates().then((response) => {
        this.globalTemplates = response.data;
      });
    },
    clearBuilder() {
      this.template = {
        name: null,
        reference_name: null,
        description: null,
        is_global: false,
      };
      this.globalTemplates = null;
      this.data = [];
    },
  },
});
