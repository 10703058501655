import { client } from '@/api/client';

const getCurrentUser = () => {
  return client.get(`/user`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export default {
  getCurrentUser,
};
