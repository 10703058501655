import { client } from '@/api/client';

const index = () => {
  return client.get(`/admin/language`);
};

const show = (id: number) => {
  return client.get(`/admin/language/${id}`);
};

const store = (data: { code: string; name: string }) => {
  return client.post(`/admin/language`, data);
};

const update = (data: { id: number; code: string; name: string }) => {
  return client.put(`/admin/language/${data.id}`, {
    code: data.code,
    name: data.name,
  });
};

export default {
  index,
  show,
  store,
  update,
};
